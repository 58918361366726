<template>
<div>
    <label
      v-if="label"
      class="pr-4 inline-block mb-1 text-uppercase font-bold"
      :class="[
        requiredLabel ? 'required' : '',
        italic ? 'text-italic' : '',
        opacityClass,
        labelClass
      ]"

    > {{ label }} </label>
    <div class="flex justify-between align-center rounded border border-solid border-gray-500 border w-full p-1">
        <p class="pl-2 text-base w-75p" style="word-wrap: break-word;">{{ (typeof value == 'string') ? value : fileName }}</p>
        <div class="rounded text-white p-2 text-uppercase inline-block bg-victoria pointer" @click="$refs.file.click()">
          Browse
        </div>
        <input class="hidden" type="file" ref="file" @change="getFileContent">
    </div>
    <b class="text-danger" id="scrollTop" v-if="message">{{ message }}</b>
</div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: () => null
    },
    italic: {
      type: Boolean,
      default: () => false
    },
    opacityClass: {
      type: String,
    },
    labelClass: {
      type: String
    },
    requiredLabel: {
      type: Boolean,
      default: () => false
    },
    message: {
      type: String,
      default: () => null
    },
    value: {
      
    }
  },
  data() {
    return {
      fileName: null 
    }
  },
  methods: {
    getFileContent(e) {
      this.fileName = e.target.files[0].name;
      this.$emit('input', e.target.files[0]);
    }
  }
}
</script>

<style>

</style>